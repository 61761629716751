<template>
  <div class="page">
    <div style="background: #fff;"
         class="pt-2 pb-2 pl-4 color-warning"
         @click="toList">去列表页</div>
    <div class="p-footer h-end">
      <button class="btn auto fill ml-3"
              @click="toIndex()">获取时间</button>
    </div>
    <div class="s2 p-card fs-16 m-3 pt-2 pb-2 pl-2 pb-2">
      <div class="flex fs-16 px-3">
        <div>保存内容：</div>
        <div class="flex-1"><input v-model="text"
                 type="text"
                 placeholder="请输入描述"
                 class="inp"></div>
      </div>
    </div>

    <div class="s2 p-card fs-16 m-3 pt-2 pb-2 pl-2 pb-2">
      <div v-for="(el,index) in list"
           class="mt-2"
           :key="el">
        第{{index+1}}次 ：{{el.value}} — {{el.key}}
      </div>
    </div>

    <div class="p-footer h-end">
      <div class="flex  flex-right">
        <button class="btn auto fill ml-3"
                @click="submit(1)">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WelcomeWords',
  components: {},
  data () {
    return {
      status: false,
      chatroomList: [],
      contentMsgs: [],
      list: [],
      text: ''
    }
  },
  computed: {
    ...mapGetters(['globalData'])
  },
  created () {
    // this.toIndex();
  },
  methods: {
    toIndex () {
      this.list = [];
      // 五分钟
      let value = [
        { key: '5分钟', value: 1000 * 60 * 5, checked: false },
        { key: '30分钟', value: 1000 * 60 * 30, checked: false },
        { key: '12小时', value: 1000 * 60 * 60 * 12, checked: false },
        { key: '1天', value: 1000 * 60 * 60 * 24, checked: false },
        { key: '2天', value: 1000 * 60 * 60 * 24 * 2, checked: false },
        { key: '4天', value: 1000 * 60 * 60 * 24 * 4, checked: false },
        { key: '7天', value: 1000 * 60 * 60 * 24 * 7, checked: false },
        { key: '15天', value: 1000 * 60 * 60 * 24 * 15, checked: false }]
      value.map(item => {
        this.list.push(this.sysTime(item));
      })
      // this.list.push(new Date((new Date()).getTime() + 1000 * 60 * 60 * 24))

    },

    sysTime (item) {

      var myDate = new Date((new Date()).getTime() + item.value);

      var year = myDate.getFullYear();

      var month = myDate.getMonth() + 1;

      var date = myDate.getDate();

      var h = myDate.getHours();

      var m = myDate.getMinutes();

      var s = myDate.getSeconds();

      var now = year + '-' + this.getMakeZero(month) + "-" + this.getMakeZero(date) + " " + this.getMakeZero(h) + ':' + this.getMakeZero(m);

      return { key: item.key, value: now };
    },
    getTime () {

      var myDate = new Date();

      var year = myDate.getFullYear();

      var month = myDate.getMonth() + 1;

      var date = myDate.getDate();

      var now = year + '-' + this.getMakeZero(month) + "-" + this.getMakeZero(date);

      return now;
    },
    getMakeZero (s) {
      return s < 10 ? '0' + s : s;
    },
    submit () {
      let idValue;
      // debugger
      if (localStorage.getItem("idValue")) {
        idValue = parseInt(localStorage.getItem("idValue")) + 1;
        console.log(idValue)
        localStorage.setItem("idValue", idValue);
      } else {
        idValue = 1;
        localStorage.setItem("idValue", idValue);
      }
      this.list[0].content = this.text;
      this.list[0].id = idValue;
      localStorage.setItem("idValue" + idValue, JSON.stringify(this.list))
      let content = "";
      if (!localStorage.getItem("time" + this.getTime())) {
        content = "idValue" + idValue
      } else {
        content = localStorage.getItem("time" + this.getTime()) + "," + "idValue" + idValue
      }
      localStorage.setItem(("time" + this.getTime()), content)
    },
    toList () {
      let _this = this;
      _this.$router.push({ path: '/keywords/list' })
    },
    toDetail () {
      let _this = this;
      _this.$router.push({ path: '/keywords/detail' })
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
